<template>
  <!-- Navbar Start -->
  <nav
    class="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0"
  >
    <a
      href="/"
      class="navbar-brand d-flex align-items-center px-4 px-lg-5"
    >
      <h2 class="m-0 text-primary"><i class="fa fa-book me-3"></i>Elrukz Group Education</h2>
    </a>
    <button
      type="button"
      class="navbar-toggler me-4"
      data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <div class="navbar-nav ms-auto p-4 p-lg-0">
        <a href="/" class="nav-item nav-link active">Home</a>
        <a href="/about" class="nav-item nav-link">About</a>
        <a href="/services" class="nav-item nav-link">Services</a>
        <div class="nav-item dropdown">
          <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
            >Pages</a
          >
          <div class="dropdown-menu fade-down m-0">
            <a href="#" class="dropdown-item">Our Team</a>
            <a href="#" class="dropdown-item">Testimonial</a>
            <!-- <a href="404.html" class="dropdown-item">404 Page</a> -->
          </div>
        </div>
        <a href="/contact" class="nav-item nav-link">Contact</a>
      </div>
      <a href="" class="btn btn-primary py-4 px-lg-5 d-none d-lg-block"
        >Join Now<i class="fa fa-arrow-right ms-3"></i
      ></a>
    </div>
  </nav>
  <!-- Navbar End -->

  <!-- Carousel Start -->
  <div class="container-fluid p-0 mb-5">
    <div>
      <div class="owl-carousel-item  position-relative">
        <div
        class="position-relative"
        style="height: 500px; overflow: hidden"
      >
        <img
          class="img-fluid w-100 position-absolute top-0 start-0"
          src="img/carousel-1.jpg"
          alt=""
        />
        <div
          class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
          style="background: rgba(24, 29, 56, 0.7)"
        >
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-sm-10 col-lg-8">
                <h5
                  class="text-primary text-uppercase mb-3 animated slideInDown"
                >
                  Study Abroad
                </h5>
                <h1 class="display-3 text-white animated slideInDown">
                  Elrukz Group Education Consultancy
                </h1>
                <p class="fs-5 text-white mb-4 pb-2">
                  Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam
                  no. Kasd rebum ipsum et diam justo clita et kasd rebum sea
                  sanctus eirmod elitr.
                </p>
                <a
                  href=""
                  class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >Read More</a
                >
                <a
                  href=""
                  class="btn btn-light py-md-3 px-md-5 animated slideInRight"
                  >Join Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
  <!-- Carousel End -->

  <!-- Service Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="row g-4">
        <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
          <div class="service-item text-center pt-3">
            <div class="p-4">
              <i class="fa fa-3x fa-graduation-cap text-primary mb-4"></i>
              <h5 class="mb-3">Study Abroad</h5>
              <p>
                Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita
                amet diam
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
          <div class="service-item text-center pt-3">
            <div class="p-4">
              <i class="fa fa-3x fa-globe text-primary mb-4"></i>
              <h5 class="mb-3">Full Scholarship</h5>
              <p>
                Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita
                amet diam
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
          <div class="service-item text-center pt-3">
            <div class="p-4">
              <i class="fa fa-3x fa-home text-primary mb-4"></i>
              <h5 class="mb-3">Job Opportunity Abroad</h5>
              <p>
                Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita
                amet diam
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
          <div class="service-item text-center pt-3">
            <div class="p-4">
              <i class="fa fa-3x fa-book-open text-primary mb-4"></i>
              <h5 class="mb-3">IT Consultancy</h5>
              <p>
                Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita
                amet diam
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Service End -->

  <!-- About Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="row g-5">
        <div
          class="col-lg-6 wow fadeInUp"
          data-wow-delay="0.1s"
          style="min-height: 400px"
        >
          <div class="position-relative h-100">
            <img
              class="img-fluid position-absolute w-100 h-100"
              src="img/about.jpg"
              alt=""
              style="object-fit: cover"
            />
          </div>
        </div>
        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
          <h6 class="section-title bg-white text-start text-primary pe-3">
            About Us
          </h6>
          <h1 class="mb-4">Welcome to Elrukz Group Education Consultancy</h1>
          <p class="mb-4">
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
            diam amet diam et eos. Clita erat ipsum et lorem et sit.
          </p>
          <p class="mb-4">
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
            diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet
            lorem sit clita duo justo magna dolore erat amet
          </p>
          <div class="row gy-2 gx-4 mb-4">
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-primary me-2"></i>Skilled
                Instructors
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-primary me-2"></i>Online
                Classes
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-primary me-2"></i>International
                Certificate
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-primary me-2"></i>Skilled
                Instructors
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-primary me-2"></i>Online
                Classes
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-primary me-2"></i>International
                Certificate
              </p>
            </div>
          </div>
          <a class="btn btn-primary py-3 px-5 mt-2" href="">Read More</a>
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->

  <!-- Categories Start -->
  <div class="container-xxl py-5 category">
    <div class="container">
      <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 class="section-title bg-white text-center text-primary px-3">
          Categories
        </h6>
        <h1 class="mb-5">Service Categories</h1>
      </div>
      <div class="row g-3">
        <div class="col-lg-7 col-md-6">
          <div class="row g-3">
            <div class="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
              <a class="position-relative d-block overflow-hidden" href="">
                <img class="img-fluid" src="img/cat-1.jpg" alt="" />
                <div
                  class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                  style="margin: 1px"
                >
                  <h5 class="m-0">Study Abroad</h5>
                  <small class="text-primary">120 served</small>
                </div>
              </a>
            </div>
            <div class="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.3s">
              <a class="position-relative d-block overflow-hidden" href="">
                <img class="img-fluid" src="img/cat-2.jpg" alt="" />
                <div
                  class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                  style="margin: 1px"
                >
                  <h5 class="m-0">Full Scholarship</h5>
                  <small class="text-primary">49 Scholarship</small>
                </div>
              </a>
            </div>
            <div class="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.5s">
              <a class="position-relative d-block overflow-hidden" href="">
                <img class="img-fluid" src="img/cat-3.jpg" alt="" />
                <div
                  class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                  style="margin: 1px"
                >
                  <h5 class="m-0">Job Opportunity</h5>
                  <small class="text-primary">20 Opportunity</small>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          class="col-lg-5 col-md-6 wow zoomIn"
          data-wow-delay="0.7s"
          style="min-height: 350px"
        >
          <a class="position-relative d-block h-100 overflow-hidden" href="">
            <img
              class="img-fluid position-absolute w-100 h-100"
              src="img/cat-4.jpg"
              alt=""
              style="object-fit: cover"
            />
            <div
              class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
              style="margin: 1px"
            >
              <h5 class="m-0">IT Consultancy</h5>
              <small class="text-primary">50 served</small>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Categories Start -->

  <!-- Courses Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 class="section-title bg-white text-center text-primary px-3">
          Study Abroad
        </h6>
        <h1 class="mb-5">Popular Country</h1>
      </div>
      <div class="row g-4 justify-content-center">
        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div class="course-item bg-light">
            <div class="position-relative overflow-hidden">
              <img class="img-fluid" src="img/course-1.jpg" alt="" />
              <div
                class="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4"
              >
                <a
                  href="#"
                  class="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                  style="border-radius: 30px 0 0 30px"
                  >Read More</a
                >
                <a
                  href="#"
                  class="flex-shrink-0 btn btn-sm btn-primary px-3"
                  style="border-radius: 0 30px 30px 0"
                  >Join Now</a
                >
              </div>
            </div>
            <div class="text-center p-4 pb-0">
              <!-- <h3 class="mb-0">$149.00</h3> -->
              <div class="mb-3">
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small>(123)</small>
              </div>
              <h5 class="mb-4">
                Study in Canada
              </h5>
            </div>
            <div class="d-flex border-top">
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-user-tie text-primary me-2"></i>John Doe</small
              >
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-clock text-primary me-2"></i>1.49 Hrs</small
              >
              <small class="flex-fill text-center py-2"
                ><i class="fa fa-user text-primary me-2"></i>30 Students</small
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div class="course-item bg-light">
            <div class="position-relative overflow-hidden">
              <img class="img-fluid" src="img/course-2.jpg" alt="" />
              <div
                class="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4"
              >
                <a
                  href="#"
                  class="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                  style="border-radius: 30px 0 0 30px"
                  >Read More</a
                >
                <a
                  href="#"
                  class="flex-shrink-0 btn btn-sm btn-primary px-3"
                  style="border-radius: 0 30px 30px 0"
                  >Join Now</a
                >
              </div>
            </div>
            <div class="text-center p-4 pb-0">
              <!-- <h3 class="mb-0">$149.00</h3> -->
              <div class="mb-3">
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small>(123)</small>
              </div>
              <h5 class="mb-4">
                Study in Europe school
              </h5>
            </div>
            <div class="d-flex border-top">
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-user-tie text-primary me-2"></i>John Doe</small
              >
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-clock text-primary me-2"></i>1.49 Hrs</small
              >
              <small class="flex-fill text-center py-2"
                ><i class="fa fa-user text-primary me-2"></i>30 Students</small
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div class="course-item bg-light">
            <div class="position-relative overflow-hidden">
              <img class="img-fluid" src="img/course-3.jpg" alt="" />
              <div
                class="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4"
              >
                <a
                  href="#"
                  class="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                  style="border-radius: 30px 0 0 30px"
                  >Read More</a
                >
                <a
                  href="#"
                  class="flex-shrink-0 btn btn-sm btn-primary px-3"
                  style="border-radius: 0 30px 30px 0"
                  >Join Now</a
                >
              </div>
            </div>
            <div class="text-center p-4 pb-0">
              <!-- <h3 class="mb-0">$149.00</h3> -->
              <div class="mb-3">
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small class="fa fa-star text-primary"></small>
                <small>(123)</small>
              </div>
              <h5 class="mb-4">
                Study in Poland
              </h5>
            </div>
            <div class="d-flex border-top">
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-user-tie text-primary me-2"></i>John Doe</small
              >
              <small class="flex-fill text-center border-end py-2"
                ><i class="fa fa-clock text-primary me-2"></i>1.49 Hrs</small
              >
              <small class="flex-fill text-center py-2"
                ><i class="fa fa-user text-primary me-2"></i>30 Students</small
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Courses End -->

  <!-- Team Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 class="section-title bg-white text-center text-primary px-3">
          Instructors
        </h6>
        <h1 class="mb-5">Expert Instructors</h1>
      </div>
      <div class="row g-4">
        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div class="team-item bg-light">
            <div class="overflow-hidden">
              <img class="img-fluid" src="img/team-1.jpg" alt="" />
            </div>
            <div
              class="position-relative d-flex justify-content-center"
              style="margin-top: -23px"
            >
              <div class="bg-light d-flex justify-content-center pt-2 px-1">
                <a class="btn btn-sm-square btn-primary mx-1" href=""
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a class="btn btn-sm-square btn-primary mx-1" href=""
                  ><i class="fab fa-twitter"></i
                ></a>
                <a class="btn btn-sm-square btn-primary mx-1" href=""
                  ><i class="fab fa-instagram"></i
                ></a>
              </div>
            </div>
            <div class="text-center p-4">
              <h5 class="mb-0">Instructor Name</h5>
              <small>Designation</small>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div class="team-item bg-light">
            <div class="overflow-hidden">
              <img class="img-fluid" src="img/team-2.jpg" alt="" />
            </div>
            <div
              class="position-relative d-flex justify-content-center"
              style="margin-top: -23px"
            >
              <div class="bg-light d-flex justify-content-center pt-2 px-1">
                <a class="btn btn-sm-square btn-primary mx-1" href=""
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a class="btn btn-sm-square btn-primary mx-1" href=""
                  ><i class="fab fa-twitter"></i
                ></a>
                <a class="btn btn-sm-square btn-primary mx-1" href=""
                  ><i class="fab fa-instagram"></i
                ></a>
              </div>
            </div>
            <div class="text-center p-4">
              <h5 class="mb-0">Instructor Name</h5>
              <small>Designation</small>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div class="team-item bg-light">
            <div class="overflow-hidden">
              <img class="img-fluid" src="img/team-3.jpg" alt="" />
            </div>
            <div
              class="position-relative d-flex justify-content-center"
              style="margin-top: -23px"
            >
              <div class="bg-light d-flex justify-content-center pt-2 px-1">
                <a class="btn btn-sm-square btn-primary mx-1" href=""
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a class="btn btn-sm-square btn-primary mx-1" href=""
                  ><i class="fab fa-twitter"></i
                ></a>
                <a class="btn btn-sm-square btn-primary mx-1" href=""
                  ><i class="fab fa-instagram"></i
                ></a>
              </div>
            </div>
            <div class="text-center p-4">
              <h5 class="mb-0">Instructor Name</h5>
              <small>Designation</small>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
          <div class="team-item bg-light">
            <div class="overflow-hidden">
              <img class="img-fluid" src="img/team-4.jpg" alt="" />
            </div>
            <div
              class="position-relative d-flex justify-content-center"
              style="margin-top: -23px"
            >
              <div class="bg-light d-flex justify-content-center pt-2 px-1">
                <a class="btn btn-sm-square btn-primary mx-1" href=""
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a class="btn btn-sm-square btn-primary mx-1" href=""
                  ><i class="fab fa-twitter"></i
                ></a>
                <a class="btn btn-sm-square btn-primary mx-1" href=""
                  ><i class="fab fa-instagram"></i
                ></a>
              </div>
            </div>
            <div class="text-center p-4">
              <h5 class="mb-0">Instructor Name</h5>
              <small>Designation</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Team End -->

  <!-- Testimonial Start -->
  <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container">
      <div class="text-center">
        <h6 class="section-title bg-white text-center text-primary px-3">
          Testimonial
        </h6>
        <h1 class="mb-5">Our Students Say!</h1>
      </div>
      <div class="owl-carousel testimonial-carousel position-relative">
        <div class="testimonial-item text-center">
          <img
            class="border rounded-circle p-2 mx-auto mb-3"
            src="img/testimonial-1.jpg"
            style="width: 80px; height: 80px"
          />
          <h5 class="mb-0">Client Name</h5>
          <p>Profession</p>
          <div class="testimonial-text bg-light text-center p-4">
            <p class="mb-0">
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
              amet diam et eos. Clita erat ipsum et lorem et sit.
            </p>
          </div>
        </div>
        <div class="testimonial-item text-center">
          <img
            class="border rounded-circle p-2 mx-auto mb-3"
            src="img/testimonial-2.jpg"
            style="width: 80px; height: 80px"
          />
          <h5 class="mb-0">Client Name</h5>
          <p>Profession</p>
          <div class="testimonial-text bg-light text-center p-4">
            <p class="mb-0">
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
              amet diam et eos. Clita erat ipsum et lorem et sit.
            </p>
          </div>
        </div>
        <div class="testimonial-item text-center">
          <img
            class="border rounded-circle p-2 mx-auto mb-3"
            src="img/testimonial-3.jpg"
            style="width: 80px; height: 80px"
          />
          <h5 class="mb-0">Client Name</h5>
          <p>Profession</p>
          <div class="testimonial-text bg-light text-center p-4">
            <p class="mb-0">
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
              amet diam et eos. Clita erat ipsum et lorem et sit.
            </p>
          </div>
        </div>
        <div class="testimonial-item text-center">
          <img
            class="border rounded-circle p-2 mx-auto mb-3"
            src="img/testimonial-4.jpg"
            style="width: 80px; height: 80px"
          />
          <h5 class="mb-0">Client Name</h5>
          <p>Profession</p>
          <div class="testimonial-text bg-light text-center p-4">
            <p class="mb-0">
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
              amet diam et eos. Clita erat ipsum et lorem et sit.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Testimonial End -->

  <!-- Footer Start -->
  <div
    class="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div class="container py-5">
      <div class="row g-5">
        <div class="col-lg-3 col-md-6">
          <h4 class="text-white mb-3">Quick Link</h4>
          <a class="btn btn-link" href="">About Us</a>
          <a class="btn btn-link" href="">Contact Us</a>
          <a class="btn btn-link" href="">Privacy Policy</a>
          <a class="btn btn-link" href="">Terms & Condition</a>
          <a class="btn btn-link" href="">FAQs & Help</a>
        </div>
        <div class="col-lg-3 col-md-6">
          <h4 class="text-white mb-3">Contact</h4>
          <p class="mb-2">
            <i class="fa fa-map-marker-alt me-3"></i>123 Street, Remera, Kigali
          </p>
          <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>0789326245</p>
          <p class="mb-2">
            <i class="fa fa-envelope me-3"></i>info@example.com
          </p>
          <div class="d-flex pt-2">
            <a class="btn btn-outline-light btn-social" href=""
              ><i class="fab fa-twitter"></i
            ></a>
            <a class="btn btn-outline-light btn-social" href=""
              ><i class="fab fa-facebook-f"></i
            ></a>
            <a class="btn btn-outline-light btn-social" href=""
              ><i class="fab fa-youtube"></i
            ></a>
            <a class="btn btn-outline-light btn-social" href=""
              ><i class="fab fa-linkedin-in"></i
            ></a>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h4 class="text-white mb-3">Gallery</h4>
          <div class="row g-2 pt-2">
            <div class="col-4">
              <img
                class="img-fluid bg-light p-1"
                src="img/course-1.jpg"
                alt=""
              />
            </div>
            <div class="col-4">
              <img
                class="img-fluid bg-light p-1"
                src="img/course-2.jpg"
                alt=""
              />
            </div>
            <div class="col-4">
              <img
                class="img-fluid bg-light p-1"
                src="img/course-3.jpg"
                alt=""
              />
            </div>
            <div class="col-4">
              <img
                class="img-fluid bg-light p-1"
                src="img/course-2.jpg"
                alt=""
              />
            </div>
            <div class="col-4">
              <img
                class="img-fluid bg-light p-1"
                src="img/course-3.jpg"
                alt=""
              />
            </div>
            <div class="col-4">
              <img
                class="img-fluid bg-light p-1"
                src="img/course-1.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h4 class="text-white mb-3">Newsletter</h4>
          <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
          <div class="position-relative mx-auto" style="max-width: 400px">
            <input
              class="form-control border-0 w-100 py-3 ps-4 pe-5"
              type="text"
              placeholder="Your email"
            />
            <button
              type="button"
              class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
            >
              SignUp
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="copyright">
        <div class="row">
          <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
            &copy; <a class="border-bottom" href="#">Your Site Name</a>, All
            Right Reserved.
            Designed By
            <a class="border-bottom" href="#">Elrukz Group Education</a>
          </div>
          <div class="col-md-6 text-center text-md-end">
            <div class="footer-menu">
              <a href="">Home</a>
              <a href="">Cookies</a>
              <a href="">Help</a>
              <a href="">FQAs</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer End -->

  <!-- Back to Top -->
  <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"
    ><i class="bi bi-arrow-up"></i
  ></a>
</template>
